<template>
  <div class="container">
    <div class="head">
      <img src="../../assets/images/yuwan/moveHead.jpg" />
    </div>
    <div class="content">
      <div class="baseInfo">
        <div class="baseTitle">参赛证</div>
        <div class="infoMassage">
          <div class="leftTit">学&nbsp;&nbsp;校:</div>
          <!-- <el-input v-model="name" class="pass_input" /> -->
          <div class="pass_input">
            <span v-if="personInfo">{{ personInfo.school }}</span>
          </div>
        </div>
        <div class="infoMassage">
          <div class="leftTit">姓&nbsp;&nbsp;名:</div>
          <div class="pass_input">
            <span v-if="personInfo">{{ personInfo.name }}</span>
          </div>
        </div>
        <div class="infoMassage">
          <div class="leftTit">手机号:</div>
          <div class="pass_input">
            <span v-if="personInfo">{{ personInfo.phone }}</span>
          </div>
        </div>
      </div>
      <div class="baseInfo">
        <div v-if="dataList.length > 0">
          <div
            v-for="(item, index) in dataList"
            :key="index"
            style="margin-top: 10px"
          >
            <div
              v-if="
                item &&
                item.exam_team_use_info &&
                item.exam_team_use_info.length > 0
              "
            >
              <div v-if="item.track == '创新'">
                <div class="baseTitle">产教融合5G+创新应用设计赛</div>
                <div class="infoMassage">
                  <div class="leftTit">赛&nbsp;&nbsp;项:</div>
                  <div class="pass_input">
                    <span>{{ item.group }}</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">参赛证号:</div>
                  <div class="pass_input">
                    <span>{{ item.examination_card_number }}</span>
                  </div>
                </div>
                <div
                  class=""
                  v-for="(childItem, i) in item.exam_team_use_info"
                  :key="i"
                >
                  <div class="infoMassage" v-if="childItem.use == '创新赛'">
                    <div class="leftTit">答辩场所:</div>
                    <div class="pass_input">
                      <span>{{ childItem.place }}</span>
                    </div>
                  </div>
                  <div class="infoMassage" v-if="childItem.use == '创新赛'">
                    <div class="leftTit">答辩时间:</div>
                    <div class="pass_input">
                      <span>{{ childItem.time }}</span>
                    </div>
                  </div>
                </div>
                <!--
              <div class="infoMassage">
                <div class="leftTit">候场地点:</div>
                <div class="pass_input">
                  <span>{{}}</span>
                </div>
              </div> -->
              </div>

              <div v-else-if="item.track == '教师'">
                <div class="baseTitle">教师赛赛场信息</div>
                <div class="infoMassage">
                  <div class="leftTit">组&nbsp;&nbsp;别:</div>
                  <div class="pass_input">
                    <span>{{ item.group }}组</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">参赛证号:</div>
                  <div class="pass_input">
                    <span>{{ item.examination_card_number }}</span>
                  </div>
                </div>
              </div>
              <div v-else-if="item.track == '学生'">
                <div class="baseTitle">信息通信工程实践赛</div>
                <div class="infoMassage">
                  <div class="leftTit">组&nbsp;&nbsp;别:</div>
                  <div class="pass_input">
                    <span>{{ item.group }}组</span>
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">参赛证号:</div>
                  <div class="pass_input">
                    <span>{{ item.examination_card_number }}</span>
                  </div>
                </div>
              </div>

              <div
                class=""
                v-for="(childItem, i) in item.exam_team_use_info"
                :key="i"
              >
                <div class="infoMassage" v-if="childItem.use == '实践赛'">
                  <div class="leftTit">
                    <span>5G商用设备</span>
                    <span>实践赛地点:</span>
                  </div>
                  <div class="pass_input">
                    <span>{{ childItem.place }}</span>
                  </div>
                </div>
                <div class="infoMassage" v-if="childItem.use == '实践赛'">
                  <div class="leftTit">
                    <span>5G商用设备</span> <span> 实践赛时间:</span>
                  </div>
                  <div class="pass_input">
                    <span>{{ childItem.time }}</span>
                  </div>
                </div>
                <div class="infoMassage" v-if="childItem.use == '仿真赛'">
                  <div class="leftTit">
                    <span>5G工程实践</span><span>仿真赛地点:</span>
                  </div>
                  <div class="pass_input">
                    <span>{{ childItem.place }}</span>
                  </div>
                </div>
                <div class="infoMassage" v-if="childItem.use == '仿真赛'">
                  <div class="leftTit">
                    <span>5G工程实践</span><span>仿真赛时间:</span>
                  </div>
                  <div class="pass_input">
                    <span>{{ childItem.time }}</span>
                  </div>
                </div>
              </div>

              <div class="infoMassage">
                <div class="leftTit">
                  <span>全国总决赛</span><span>报到地点:</span>
                </div>
                <div class="pass_input">
                  <span v-if="item.exam_registration">{{
                    item.exam_registration.place
                  }}</span>
                </div>
              </div>
              <div class="infoMassage">
                <div class="leftTit">
                  <span>全国总决赛</span><span>报到时间:</span>
                </div>
                <div class="pass_input">
                  <span v-if="item.exam_registration">{{
                    item.exam_registration.time
                  }}</span>
                </div>
              </div>
              <div
                v-if="item.appointment_info === true"
                class="inquireBtn"
                type="primary"
              >
                <span @click="next(item)"
                  >点击预约赛前练习（5G商用设备赛）</span
                >
              </div>
              <div v-else-if="item.appointment_info === false"></div>
              <div v-else>
                <div class="infoMassage">
                  <div class="leftTit">赛前练习地点:</div>
                  <div class="pass_input">
                    <span v-if="item.appointment_info"
                      >{{ item.appointment_info.area }}-{{
                        item.appointment_info.place
                      }}</span
                    >
                  </div>
                </div>
                <div class="infoMassage">
                  <div class="leftTit">
                    <span>赛前练习</span> <span>预约时间:</span>
                  </div>
                  <div class="pass_input">
                    <span v-if="item.appointment_info.begin_time">{{
                      item.appointment_info.appointmentTime
                    }}</span>
                  </div>
                </div>
                <!-- <div class="inquireBtn">
                <span
                  :disabled="item.appointment_info == false"
                  style="background: #e0e0e4"
                  >点击预约赛前练习（5G商用设备赛）</span
                >
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 二维码图片 -->
      <div class="pic">
        <div class="idCardText">参赛选手务必携带身份证入校</div>
        <span class="tit">
          截图保存二维码，扫描查看准考证信息，请妥善保管。
        </span>
      </div>
      <div class="picCont">
        <img :src="pic" width="140px" />
      </div>
      <div class="descTitle">
        <p class="matter">注意事项：</p>
        <div class="partTime">
          <div class="leftT">
            1、 参赛证领取时间:&nbsp;2024年7月7日9:00(武汉赛点)
          </div>
          <div class="rightT">2024年7月18日9:00(北京赛点)</div>
        </div>

        <p>2、 参赛证领取后即可预约赛前练习(本科A组、本科B组、高职组)</p>
        <p>3、 赛前练习仅可预约一次，确认后无法修改</p>
        <p>
          4、 入校报到时请出示参赛证，<span class="keyDesc"
            >请在报到时间内前往报到处领取竞赛资料袋</span
          >。
        </p>
      </div>
    </div>
  </div>
</template>
 
<script>
import { ElMessage } from "element-plus";
import { moveAppointment } from "api/apis.js";
export default {
  data() {
    return {
      iphone: "",
      name: "",
      formInline: {
        name: "",
        idCard: "",
        school: "",
        iphone: "",
      },
      dataList: [],
      personInfo: [], //个人基础信息
      pic: "",
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    getInfo() {
      this.dataList = [];
      this.iphone = this.$route.query.iphone;
      localStorage.setItem("iphone", this.iphone);
      let idStr = this.$route.query.idList || "";
      localStorage.setItem("idList", idStr);
      let idList = idStr.split(",") || [];
      let obj = new FormData();
      let jumpStr = false;
      let team_ids = [];
      obj.append("phone", this.iphone);
      obj.append("team_ids", idStr);
      //获取二维码图片
      moveAppointment.grcode(obj).then((response) => {
        if (response.code === 200) {
          this.pic = response.url;
        }
      });
      idList.forEach((element) => {
        let param = new FormData();
        param.append("phone", this.iphone);
        param.append("team_id", element);
        moveAppointment.select(param).then((response) => {
          if (response.code === 200) {
            let item  = JSON.parse(response.info);
            this.dataList.push(item);
            team_ids.push(element);

            if (
                item &&
                item.exam_team_use_info &&
                item.exam_team_use_info.length > 0
              ) {
                jumpStr = true;
                this.personInfo = item;

                if (item.appointment_info.begin_time) {
                  item.appointment_info.appointmentTime =
                    item.appointment_info.begin_time +
                    "-" +
                    item.appointment_info.end_time.split(" ")[1];
                }
              }

            if (team_ids.length == idList.length && jumpStr == false) {
                ElMessage({
                  message: "未找到全国总决赛参赛证信息",
                  type: "error",
                });
                this.$router.push({
                  path: "/ExamArrangement",
                });
              }
          }
        });
      });
    },
    next(item) {
      console.log(item);
      localStorage.setItem("team_id", item.team_id);
      localStorage.setItem("track", item.track);
      localStorage.setItem("group", item.group);
      localStorage.setItem("province", item.province);
      this.$router.push({
        path: "/appoTime",
        query: { team_id: item.team_id, track: item.track },
      });
    },
  },
};
</script>
<style scoped lang="scss">
@import "./appointment.scss";
</style>